import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { Dropdown} from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./TabSearchResult.scss";
import NewTabSearch from "./NewTabSearch";
import { v4 as uuidv4 } from "uuid";
//item images

import ImgMain1 from "../../../images/SearchResult/img-main-1.jpg";
import List1GridImg1 from "../../../images/SearchResult/list1grid-img1.jpg";
import List1GridImg2 from "../../../images/SearchResult/list1grid-img2.jpg";
import List1GridImg3 from "../../../images/SearchResult/list1grid-img3.jpg";

import ImgMain2 from "../../../images/SearchResult/img-main-2.jpg";
import List2GridImg1 from "../../../images/SearchResult/list2grid-img1.jpg";
import List2GridImg2 from "../../../images/SearchResult/list2grid-img2.jpg";
import List2GridImg3 from "../../../images/SearchResult/list2grid-img3.jpg";

import ImgMain3 from "../../../images/SearchResult/img-main-3.jpg";
import List3GridImg1 from "../../../images/SearchResult/list3grid-img1.jpg";
import List3GridImg2 from "../../../images/SearchResult/list3grid-img2.jpg";
import List3GridImg3 from "../../../images/SearchResult/list3grid-img3.jpg";

import ImgMain4 from "../../../images/SearchResult/img-main-4.jpg";
import List4GridImg1 from "../../../images/SearchResult/list4grid-img1.jpg";
import List4GridImg2 from "../../../images/SearchResult/list4grid-img2.jpg";
import List4GridImg3 from "../../../images/SearchResult/list4grid-img3.jpg";

import ImgMain5 from "../../../images/SearchResult/img-main-5.jpg";
import List5GridImg1 from "../../../images/SearchResult/list5grid-img1.jpg";
import List5GridImg2 from "../../../images/SearchResult/list5grid-img2.jpg";
import List5GridImg3 from "../../../images/SearchResult/list5grid-img3.jpg";

import ImgMain6 from "../../../images/SearchResult/img-main-6.jpg";
import List6GridImg1 from "../../../images/SearchResult/list6grid-img1.jpg";
import List6GridImg2 from "../../../images/SearchResult/list6grid-img2.jpg";
import List6GridImg3 from "../../../images/SearchResult/list6grid-img3.jpg";

import ImgMain7 from "../../../images/SearchResult/img-main-7.jpg";
import List7GridImg1 from "../../../images/SearchResult/list7grid-img1.jpg";
import List7GridImg2 from "../../../images/SearchResult/list7grid-img2.jpg";
import List7GridImg3 from "../../../images/SearchResult/list7grid-img3.jpg";

import ImgMain8 from "../../../images/SearchResult/img-main-8.jpg";
import List8GridImg1 from "../../../images/SearchResult/list8grid-img1.jpg";
import List8GridImg2 from "../../../images/SearchResult/list8grid-img2.jpg";
import List8GridImg3 from "../../../images/SearchResult/list8grid-img3.jpg";

//user images
import UserImg1 from "../../../images/SearchResult/user1.png";
import UserImg2 from "../../../images/SearchResult/user2.png";
import UserImg3 from "../../../images/SearchResult/user3.png";
import UserImg4 from "../../../images/SearchResult/user4.png";
import UserImg5 from "../../../images/SearchResult/user5.png";
import UserImg6 from "../../../images/SearchResult/user6.png";
import UserImg7 from "../../../images/SearchResult/user7.png";
import UserImg8 from "../../../images/SearchResult/user8.png";

const TabSearchResult = (props) => {
  const [grid, setGrid] = useState(true);
  const [map, setMap] = useState(false);

  const showGrid = () => {
    setMap(false);
    setGrid(true);
  };

  const showMap = () => {
    setMap(true);
    setGrid(false);
  };

  const SearchResultList = [
    {
      ImageMain: ImgMain1,
      ListImageGrid1: List1GridImg1,
      ListImageGrid2: List1GridImg2,
      ListImageGrid3: List1GridImg3,
      ImgGalleryNumber: "1/14",
      ItemPrice: "1,600,000",
      ItemGuideText: "Guide Price",
      ItemAddress: "Mallard Croft, Haddenham, Buckinghamshire, HP17",
      ItemSmallDescription: "5 bedroom detached house for sale",
      ItemDetailDescription:
        "A deceptively spacious and well-presented family home in a small private cul-de-sac backing onto the village orchard and within an easy walk of the village centre.",
      UserImage1: UserImg1,
      ContactNumber: "01844 399292",
      EmailText: "Contact",
      ItemSaveText: "Save",
      video: "IdejM6wCkxA",
    },

    {
      ImageMain: ImgMain2,
      ListImageGrid1: List2GridImg1,
      ListImageGrid2: List2GridImg2,
      ListImageGrid3: List2GridImg3,
      ImgGalleryNumber: "1/17",
      ItemPrice: "1,300,000",
      ItemGuideText: "Offers in excess of",
      ItemAddress: "Priest End, Thame, OX9",
      ItemSmallDescription: "4 bedroom detached house for sale",
      ItemDetailDescription:
        "Rycote House is an imposing detached family home constructed in the late 1990's situated within the highly desirable Priest End/Lower High Street conservation area and in the very heart of Thame.",
      UserImage1: UserImg2,
      ContactNumber: "01844 399292",
      EmailText: "Contact",
      ItemSaveText: "Save",
      video: "J6UP_0SCWk0",
    },

    {
      ImageMain: ImgMain3,
      ListImageGrid1: List3GridImg1,
      ListImageGrid2: List3GridImg2,
      ListImageGrid3: List3GridImg3,
      ImgGalleryNumber: "1/14",
      ItemPrice: "1,050,000",
      ItemGuideText: "Guide Price",
      ItemAddress: "Town Farm Close, Thame, OX9",
      ItemSmallDescription: "4 bedroom detached house for sale",
      ItemDetailDescription:
        "Deceptively spacious detached property offering flexible living accommodation arranged over three floors and located within walking distance to Thame Town Centre and all its amenities.",
      UserImage1: UserImg3,
      ContactNumber: "01844 399292",
      EmailText: "Contact",
      ItemSaveText: "Save",
      video: "V8ksSGxo6no",
    },

    {
      ImageMain: ImgMain4,
      ListImageGrid1: List4GridImg1,
      ListImageGrid2: List4GridImg2,
      ListImageGrid3: List4GridImg3,
      ImgGalleryNumber: "1/14",
      ItemPrice: "1,600,000",
      ItemGuideText: "Guide Price",
      ItemAddress: "Chearsley Road, Long Crendon, HP18",
      ItemSmallDescription: "6 bedroom detached house for sale",
      ItemDetailDescription:
        "Outstanding six bedroom detached family home, enjoying private formal grounds extending to approximately 0.50 acres and enjoying far reaching views of the countryside and The Chiltern Hills.",
      UserImage1: UserImg4,
      ContactNumber: "01844 399292",
      EmailText: "Contact",
      ItemSaveText: "Save",
      video: "IdejM6wCkxA",
    },

    {
      ImageMain: ImgMain5,
      ListImageGrid1: List5GridImg1,
      ListImageGrid2: List5GridImg2,
      ListImageGrid3: List5GridImg3,
      ImgGalleryNumber: "1/17",
      ItemPrice: "1,300,000",
      ItemGuideText: "Offers in excess of",
      ItemAddress: "Priest End, Thame, OX9",
      ItemSmallDescription: "4 bedroom detached house for sale",
      ItemDetailDescription:
        "Rycote House is an imposing detached family home constructed in the late 1990's situated within the highly desirable Priest End/Lower High Street conservation area and in the very heart of Thame.",
      UserImage1: UserImg5,
      ContactNumber: "01844 399292",
      EmailText: "Contact",
      ItemSaveText: "Save",
      video: "J6UP_0SCWk0",
    },

    {
      ImageMain: ImgMain6,
      ListImageGrid1: List6GridImg1,
      ListImageGrid2: List6GridImg2,
      ListImageGrid3: List6GridImg3,
      ImgGalleryNumber: "1/14",
      ItemPrice: "1,050,000",
      ItemGuideText: "Guide Price",
      ItemAddress: "Town Farm Close, Thame, OX9",
      ItemSmallDescription: "4 bedroom detached house for sale",
      ItemDetailDescription:
        "Deceptively spacious detached property offering flexible living accommodation arranged over three floors and located within walking distance to Thame Town Centre and all its amenities.",
      UserImage1: UserImg6,
      ContactNumber: "01844 399292",
      EmailText: "Contact",
      ItemSaveText: "Save",
      video: "IdejM6wCkxA",
    },

    {
      ImageMain: ImgMain7,
      ListImageGrid1: List7GridImg1,
      ListImageGrid2: List7GridImg2,
      ListImageGrid3: List7GridImg3,
      ImgGalleryNumber: "1/14",
      ItemPrice: "1,600,000",
      ItemGuideText: "Guide Price",
      ItemAddress: "Mallard Croft, Haddenham, Buckinghamshire, HP17",
      ItemSmallDescription: "5 bedroom detached house for sale",
      ItemDetailDescription:
        "A deceptively spacious and well-presented family home in a small private cul-de-sac backing onto the village orchard and within an easy walk of the village centre.",
      UserImage1: UserImg7,
      ContactNumber: "01844 399292",
      EmailText: "Contact",
      ItemSaveText: "Save",
      video: "J6UP_0SCWk0",
    },

    {
      ImageMain: ImgMain8,
      ListImageGrid1: List8GridImg1,
      ListImageGrid2: List8GridImg2,
      ListImageGrid3: List8GridImg3,
      ImgGalleryNumber: "1/14",
      ItemPrice: "1,600,000",
      ItemGuideText: "Guide Price",
      ItemAddress: "Chearsley Road, Long Crendon, HP18",
      ItemSmallDescription: "6 bedroom detached house for sale",
      ItemDetailDescription:
        "Outstanding six bedroom detached family home, enjoying private formal grounds extending to approximately 0.50 acres and enjoying far reaching views of the countryside and The Chiltern Hills.",
      UserImage1: UserImg7,
      ContactNumber: "01844 399292",
      EmailText: "Contact",
      ItemSaveText: "Save",
      video: "V8ksSGxo6no",
    },
  ];
  return (
    <React.Fragment>
      <section className="search-result-list">
        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <div className="tab-head">
            <div className="tab-head-container">
              <ul className="tab-list">
                <li
                  onClick={showGrid}
                  className={
                    !grid ? "tab-list-item" : "active-tab tab-list-item"
                  }
                >
                  <i className="icon-list-view"></i>List View
                </li>
                <li
                  onClick={showMap}
                  className={map ? "active-tab tab-list-item" : "tab-list-item"}
                >
                  <i className="icon-map-view"></i>Map View
                </li>
              </ul>
            </div>
          </div>
        </ScrollAnimation>
        <div className={!grid ? "tab-list" : "tab-list active-tablist"}>
          <div className="tab-list-container">
            <div className="tab-content">
              <ScrollAnimation animateOnce={true} animateIn="fadeIn" className="pos-relative">
                <div className="tab-row-block">
                  <h1 className="tab-message">
                    <span className="text-bold-dark">18</span>Properties for
                    sale in Oxford, Oxfordshire <i className="icon-info"></i>
                  </h1>
                  <div className="tab-sub-links">
                    <ul>
                      <li>
                        <Link href="#">
                          <i className="icon-wishlist"></i>{" "}
                          <span className="d-none d-md-block">Save Search</span>
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <i className="icon-notification"></i>{" "}
                          <span className="d-none d-md-block">
                            Create Alert
                          </span>
                        </Link>
                      </li>
                      <li>
                                <Dropdown>
                                    <i className="icon-location-input"></i>    
                                    <Dropdown.Toggle variant="link"> Highest Price  <i className="icon-angle-down"></i></Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Highest Price</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Lowest Price</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>

                    </ul>
                  </div>
                </div>
              </ScrollAnimation>
              {SearchResultList.map((item, index) => (
                
                  <NewTabSearch
                    ImageMain={item.ImageMain}
                    ImgGalleryNumber={item.ImgGalleryNumber}
                    ListImageGrid1={item.ListImageGrid1}
                    ListImageGrid2={item.ListImageGrid2}
                    ListImageGrid3={item.ListImageGrid3}
                    ItemPrice={item.ItemPrice}
                    ItemGuideText={item.ItemGuideText}
                    ItemAddress={item.ItemAddress}
                    ItemSmallDescription={item.ItemSmallDescription}
                    ItemDetailDescription={item.ItemDetailDescription}
                    UserImage1={item.UserImage1}
                    ContactNumber={item.ContactNumber}
                    unkey={uuidv4()}
                    videoid={item.video}
                  />
               
              ))}
              <div className="btn-load-more d-lg-none">
                <Link href="#" className="btn btn-outline">
                  Load More
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={map ? "tab-list active-tablist" : "tab-list"}>
          <div className="tab-list-container"></div>
        </div>
      </section>
      
    </React.Fragment>
  );
};

export default TabSearchResult;
