import React from "react";
import { Link } from "@StarberryUtils";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Form, Container, FormControl, Row, InputGroup, Col, Breadcrumb} from "react-bootstrap";
import "./BreadcrumbPage.scss";

const BreadcrumbPage = () => {
    
    
    return (
        <React.Fragment>
            <section className="breadcrumb-section">
                <Container>
                <Breadcrumb>
                  <Breadcrumb.Item href="#" className="btn-text-link">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Expert Lettings Agent in Oxford</Breadcrumb.Item>
                </Breadcrumb>
                </Container>
            </section> 
        </React.Fragment>
    )
}

export default BreadcrumbPage;