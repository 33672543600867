import React, {useState, useEffect} from "react";
import { Link } from "@StarberryUtils";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Form, Container, Nav, Row, Tab, Col, Dropdown} from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs'
import "./SearchBanner.scss";

const SearchBanner = () => {
    const [refine, setRefine] = useState(false);
    const [moreBnt , moreSetting] = useState(false);

    const showRefine =()=>{
        setRefine(!refine)
    }
    const showSetting =()=>{
        if(window.innerWidth <= 767){
            moreSetting(!moreBnt);
        }
       
        
    }

    return (

        <React.Fragment>
           <section className="search-banner">
               <Container>
                   <Row>
                       <Col lg={12}>
                       <ScrollAnimation animateOnce={true} animateIn="fadeInUp"><h3><span className="break-text">Find your</span> <span className="stroke-text">next home</span> today.</h3></ScrollAnimation>
                       </Col>
                   </Row>
                   <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
  
                   <div className={refine ? "search-filter-block open-filter" : "search-filter-block"}>
                       <div className="filter-head" onClick={showRefine}>
                           <span>Refine Your Search</span>
                           <i className={refine ? "icon-filter-angle-up " :" icon-filter-angle-up icon-filter-angle-down"}></i>
                       </div>
                       <div className="filter-content">
                           <Form>
                           <Tab.Container defaultActiveKey="buy-tab">
                           <Row>
                          
                               <Col md={3} lg={2} className="pr-custom-md col-grid-button">
                                   <div className="">
                                   <Nav variant="pills" className="filter-button-group">
                                       
                                        <Nav.Link eventKey="buy-tab" className="btn btn-outline">Buy</Nav.Link>
                                        
                                        <Nav.Link eventKey="rent-tab" className="btn btn-outline">Rent</Nav.Link>
                                        
                                    </Nav>
                                     
                                   </div>
                                   
                               </Col>
                               <Col md={9} lg={3} className="col-grid-form">
                               <div className="filter-search-input-box">
                                    <Dropdown>
                                    <i className="icon-location-input"></i>    
                                    <Dropdown.Toggle variant="link"> All Areas </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Dropdown Item</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Dropdown Item</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Dropdown Item</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                   </div>
                               </Col>
                               <Col lg={7} className="col-grid-button-groups">
                                   <div className="filter-btn-group-right">
                                        <Button type="button" variant="outline" className={moreBnt ? "d-md-block mb-3 mb-md-0 ":"d-none d-md-block mb-3 mb-md-0"}>Price Range</Button>
                                        <Button type="button" variant="outline" className={moreBnt ? "d-md-block mb-3 mb-md-0 ":"d-none d-md-block mb-3 mb-md-0"}>Bedrooms</Button>
                                        <Button type="button" variant="outline" className={moreBnt ? "d-md-block mb-3 mb-md-0 ":"d-none d-md-block mb-3 mb-md-0"}>Type</Button>
                                        <Button type="button" variant="outline" className="d-md-none d-lg-block" onClick={showSetting}><i class="icon-more-btn"></i> More</Button>
                                   </div>
                                  
                               </Col>
                           </Row>
                           </Tab.Container>
                           </Form>
                       </div>
                   </div>
                   </ScrollAnimation>
               </Container>
           </section>
        </React.Fragment>
    )
}

export default SearchBanner;