import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BreadcrumbPage from "../../Components/SearchResult/BreadcrumbPage/BreadcrumbPage";
import SearchBanner from "../../Components/SearchResult/SearchBanner/SearchBanner";
import TabSearchResult from "../../Components/SearchResult/TabSearchResult/TabSearchResult";
const SearchResult = () => {
    return (
        <React.Fragment>
            <div className="page-head-search"><Header/></div>
            <SearchBanner/>
            <TabSearchResult/>
            <div className="breadcrumb-other-page"><BreadcrumbPage/></div>
            <Footer/>
        </React.Fragment>
    )
}

export default SearchResult